@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap');

html,
body {
  margin: 0;
  padding: 0;
  max-width: 100%;
}

body {
  font-family: "Inter", "Roboto", "Droid Sans", "Helvetica Neue", sans-serif;
  color: #282d32;
  background-color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

body #root {
  display: flex;
  flex: 1;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

p {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
